import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { FormattedNumber } from "./formatted-number";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "Formatted Number",
	notepad: "https://hackmd.io/0J4eDbAZQ9WytH74aUXWag",
};

export default (): JSX.Element => (
	<>
		<HeaderSection title="Formatted Number" subTitle="Display numerical data" />
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={FormattedNumber} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Clearly and consistently display numerical data in a user-friendly format, making it
					easier for users to interpret and understand key information.
				</Paragraph>
				<Example fn={BasicUsage} />
				<Header.H3>Format</Header.H3>
				<Paragraph>This component supports three display formats:</Paragraph>
				<Ul
					items={[
						<>
							<b>Number</b>: For standard numerical values (e.g.: counts and metrics).
						</>,
						<>
							<b>Percent</b>: For expressing proportions or rates (e.g.: conversion rates, and total
							increase of page views).
						</>,
						<>
							<b>Currency</b>: For financial values, automatically applying the appropriate currency
							symbol (e.g.: $, €, ¥).
						</>,
					]}
				/>
				<Paragraph>
					If no format is provided, the number will default to plain numbers. Read more in{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/FAN/pages/5360091147/Understanding+Number+Formats">
						Understanding Number Formats
					</a>
				</Paragraph>
				<Example fn={FormatsExample} />
				<Header.H3>Locale</Header.H3>
				<Paragraph>
					The <Code>locale</Code> parameter controls the number formatting based on regional
					standards (e.g.: using commas or periods as decimal separators). All standard locale codes
					from the ECMAScript Internationalization API are supported.
				</Paragraph>
				<Example fn={LocalesExample} />
				<Header.H3>Digits</Header.H3>
				<Paragraph>
					Control the number of digits displayed. The default maximum significant digits is 2.
				</Paragraph>
				<Example fn={DigitsExample} />
				<Header.H3>Currency</Header.H3>
				<Paragraph>
					When using the currency format, specify the currency code (e.g.: 'USD', 'EUR'). The
					default is 'USD'.
				</Paragraph>
				<Example fn={CurrenciesExample} />
				<Header.H3>Empty number</Header.H3>
				<Paragraph>
					If the provided number is empty or invalid, a placeholder (e.g.: '-') will be displayed
					with an appropriate aria-label for accessibility.
				</Paragraph>
				<Example fn={EmptyExample} />
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>FormattedNumber</Code> when
					</Paragraph>
					<Ul
						items={[
							"Displaying large numbers that benefit from formatting for readability.",
							"Showing percentages or currency values.",
							"Presenting numbers in different locales for internationalization.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>FormattedNumber</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink to="/lab/components/Tables%20and%20lists/Table">Tables</GatsbyLink>:
								Enhance data clarity and comparisons within table cells. (e.g.{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAccessibility%2F398565%2FNextGen%2FIssuesOverview%2F1%3Fconformance%3D0%252C1%252C2%252C3%252C4%26siteTargetIssueKinds%3D1%252C2%26wcagVersion%3D21%26pageSegments%3D%26issueKind%3D1%26difficulty%3D%26elementType%3D%26responsibility%3D%26siteTarget.wcagVersion%3D22%26siteTarget.conformanceLevels%3D0%252C1%252C2%252C3%252C4%26siteTarget.issueKinds%3D1%252C2%26exceptTags%3D%26includingTags%3D%26tagFilterType%3D2%26siteTarget.wcagVersion%3D21%26siteTarget.conformanceLevels%3D0%252C1%252C2%252C3%252C4%26siteTarget.issueKinds%3D1%252C2%26lang%3Den-US">
									Accessibility issues
								</a>
								).
							</>,
							<>
								Dashboards/ Reports: Highlight key metrics and trends visually. (e.g.{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FDashboard%2F5999232986%2F1352184775%2FCustom%2FDashboard%2FIndex%3Flang%3Den-US">
									Management dashboard
								</a>
								).
							</>,
							<>
								Key metric listing: Present concise summaries of critical numerical data. (e.g.{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAnalytics%2F91868%2FCustom%2FKeyMetrics%2FMetrics%3Flang%3Den-US">
									Key metrics
								</a>
								).
							</>,
							<>
								Financial Data Displays: Clearly convey monetary amounts and financial metrics.
								(e.g.
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FPPC%2F55778%2FCustom%2FBudgetAnalysis%2FIndex%3FTabSelect%3DAll.Campaigns%26lang%3Den-US">
									Budget analysis
								</a>
								).
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>FormattedNumber</Code> to existing components for visual
								consistency.
							</>,
							<>
								<b>Options</b>:
								<Ul
									items={[
										<>
											<b>Locale</b>: Adapt number formatting to the user's region (e.g., use commas
											or periods for decimal separators).
										</>,
										<>
											<b>Precision</b> (Digits): Control the number of decimal places displayed
											(e.g., show two decimal places). Avoid excessive decimal places unless
											necessary for precision.
										</>,
										<>
											<b>Currency Code</b>: Specify the two-letter or three-letter currency code
											(e.g.: 'USD', 'EUR', 'JPY'). Defaults to user's locale if not set.
										</>,
										<>
											<b>Placeholder</b>: Customize the display for empty or invalid numbers (e.g.,
											'-').
										</>,
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								The exact numerical value is critical (e.g., in scientific or technical data where
								rounding is not acceptable).
							</>,
							<>Users need the raw, unformatted number for their own calculations.</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between the number and its background.",
							<>
								Use <Code>aria-label</Code> attributes for screen readers in empty or error states.
							</>,
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Ul
						items={[<>This component comes with built-in accessibility, no extra work required.</>]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							<>
								Follow{" "}
								<a href="https://siteimprove-wgs.atlassian.net/wiki/spaces/FAN/pages/5360091147/Understanding+Number+Formats">
									Understanding Number Formats
								</a>
								.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => <FormattedNumber number={1234567.89} locale="en-US" />;
const EmptyExample = () => <FormattedNumber number={null} />;
const LocalesExample = () => (
	<>
		<FormattedNumber number={1234567.89} locale="da-DK" format="number" />
		<br />
		<FormattedNumber number={1234567.89} locale="en-US" format="number" />
		<br />
		<FormattedNumber number={1234567.89} locale="pt-BR" format="number" />
	</>
);
const FormatsExample = () => (
	<>
		<FormattedNumber number={1234567.89} locale="en-US" format="percent" />
		<br />
		<FormattedNumber number={1234567.89} locale="en-US" format="currency" />
		<br />
		<FormattedNumber number={1234567.89} locale="en-US" format="number" />
	</>
);
const CurrenciesExample = () => (
	<FormattedNumber number={1234567.89} format="currency" currency="AUD" />
);
const DigitsExample = () => (
	<>
		<FormattedNumber number={1.2} locale="en-US" format="number" digits={3} />
		<br />
		<FormattedNumber number={1.2} locale="en-US" format="number" digits={3} alwaysShowDigits />
		<br />
		<FormattedNumber number={1.2468} locale="en-US" format="number" />
		<br />
		<FormattedNumber number={1.2468} locale="en-US" format="number" digits={3} alwaysShowDigits />
	</>
);
